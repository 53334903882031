import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { GenService } from '../../services/gen/gen.service';

@Component({
  selector: 'app-modal-convert-closing',
  templateUrl: './modal-convert-closing.page.html',
  styleUrls: ['./modal-convert-closing.page.scss'],
})
export class ModalConvertClosingPage implements OnInit {
  data : any = {}
  number : any;
  lazy : boolean = false;
  constructor(
    private modaCtrl : ModalController,
    private api: ApiService,
    private gen : GenService
  ) { 
   
    
  }

  ngOnInit() {
    console.log(this.number);
  }

  cancel(){
    this.modaCtrl.dismiss('closed')
  }

  submit(){
    this.lazy = true;
    this.data.from = this.number;
    this.api.postClosing(this.data).then((resp)=>{
      if(resp) {
        this.lazy = false;
        this.gen.alert_m('Berhasil','Closing berhasil')
        this.modaCtrl.dismiss('closed')}
    })
  }

}
