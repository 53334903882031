import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-detail-contact',
  templateUrl: './detail-contact.page.html',
  styleUrls: ['./detail-contact.page.scss'],
})
export class DetailContactPage implements OnInit {

  constructor(
    private popover : PopoverController
  ) { }

  ngOnInit() {
  }

  close(){
    this.popover.dismiss()
  }

}
