import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-review',
  templateUrl: './image-review.page.html',
  styleUrls: ['./image-review.page.scss'],
})
export class ImageReviewPage implements OnInit {
  img1;
  caption : any;
  constructor(
    private modalCtrl : ModalController
  ) { 
    console.log(this.img1);
  }

  ngOnInit() {
  }

  send(){
    this.modalCtrl.dismiss(this.caption)
  }

  close(){
    this.modalCtrl.dismiss('none')
  }

}
