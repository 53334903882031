import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-modal-show-media',
  templateUrl: './modal-show-media.page.html',
  styleUrls: ['./modal-show-media.page.scss'],
})
export class ModalShowMediaPage implements OnInit {
  media : any;
  url : any;
  lazy : boolean = true;
  constructor(
    private modalCtr : ModalController,
    private api : ApiService
  ) {
   }

  ngOnInit() {
    console.log(this.media);
    this.getMedia();
  }

  getMedia(){
    let med : any = {
      message : this.media
    }
    // let body = JSON.stringify(med)
    // this.api.downloadMedia(body).then((resp)=>{
    //   if(resp) this.lazy = false
    //   console.log('res media',resp);
    //       let id = this.media.id;
    //       var new_id = id.replace("@", "-");
    //       this.url = resp.url+new_id
    //       console.log("URI",this.url);
          
    // })
    let id = this.media.id;
    this.lazy = false
    var new_id = id.replace("@", "-");
    this.url ='http://waapi.scraping2api.com/media/5/'+new_id+'/'+new_id+'.jpeg'
    console.log(this.url);
    
  }

  close(){
    this.modalCtr.dismiss()
  }

}
